<style lang="less" scoped >
.common-activies {
  width: 100%;
  height: 942px;
  position: relative;
  margin-top: 130px;
}
.about-us-fifth {
  position: relative;
  width: 76.7%;
  max-width: 1104px;
  height: 100%;
  margin: 0 auto;
}
</style>
<template>
  <div class="common-activies">
    <div class="about-us-fifth">
      <activities />
      <news />
    </div>
  </div>
</template>

<script>
import activities from './activities.vue';
import news from './news.vue';
export default {
  name: 'common-activies',
  props: {
    msg: String
  },
  data () {
    return {
    }
  },
  created () {
  },
  methods: {
    linkTo (url, type) {
      if (type) {
        window.open(url, type);
      } else {
        this.$router.push({
          name: url
        })
      }
    },
    linkToNews (item) {
      if (item.source === 0) {
        this.$router.push({
          name: 'news',
          params: item
        })
      } else {
        this.linkTo(item.link, '_blank');
      }
    }
  },
  components: {
    activities,
    news
  }
}
</script>
