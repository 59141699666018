<style lang="less" scoped>
.master-view {
  width: 100%;
  background: #070911;
  &-first {
    width: 100%;
    position: relative;
    img {
      width: 100%;
    }
    &-container {
      width: 76.7%;
      max-width: 1104px;
      color: rgba(255, 255, 255, 1);
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -40%);
      p {
        margin: 0;
        font-family: "PingFangSC-Regular", "PingFang SC";
        &:nth-child(1) {
          font-size: 20px;
          font-weight: 400;
          color: rgba(249, 98, 47, 1);
          line-height: 28px;
          letter-spacing: 3px;
        }
        &:nth-child(2) {
          font-size: 55px;
          font-weight: 600;
          color: rgba(255, 255, 255, 1);
          line-height: 60px;
          margin: 18px 0 69px;
        }
        &:nth-child(4) {
          font-size: 18px;
          font-weight: 400;
          color: rgba(255, 255, 255, 1);
          letter-spacing: 1px;
          margin-top: 220px;
        }
      }
    }
  }
  &-third {
    width: 100%;
    height: 800px;
    background: #0f1118;
    &-container {
      width: 76.7%;
      max-width: 1104px;
      color: rgba(255, 255, 255, 1);
      margin: 0 auto;
      &-top {
        padding-top: 95px;
        display: flex;
        justify-content: space-between;
        .search {
          width: 113px;
          height: 40px;
          border-radius: 4px;
          border: 1px solid rgba(222, 230, 255, 1);
          text-align: center;
          line-height: 40px;
          font-size: 10px;
          font-weight: 500;
          letter-spacing: 1px;
          cursor: pointer;
        }
      }
      &-main {
        margin-top: 46px;
      }
      &-main-item {
        width: 100%;
        height: 70px;
        line-height: 70px;
        display: flex;
        justify-content: space-between;
        font-size: 20px;
        font-weight: 400;
        p {
          margin: 0;
        }
        &:hover {
          cursor: pointer;
          background: rgba(7, 9, 17, 1);
        }
      }
    }
  }
  .r-button {
    width: 140px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
    background: linear-gradient(
      211deg,
      rgba(248, 40, 108, 1) 0%,
      rgba(249, 98, 47, 1) 100%
    );
    border-radius: 29px;
  }
}
</style>

<template>
  <div class="master-view">
    <div class="master-view-first">
      <img src="../assets/master/home_master.png"
           alt="">
      <div class="master-view-first-container">
        <p>
          长三角创意经济合作专业委员会
        </p>
        <p>
          CREATIVE ECONOMY <br />
          COOPERATION COMMITTEE
        </p>
        <div class="r-button"
             @click="toAboutUsDetails">
          {{$t('readMore')}}
        </div>
        <p>
          战略合作： 荷兰罗斯加德工作室 /Normal Studio /Lucion Média /MASSIVart /Playmind /L'Eloi
        </p>
      </div>
    </div>
    <div class="master-view-third">
      <div class="master-view-third-container">
        <div class="master-view-third-container-top">
          <div>
            <el-input v-model="input"
                      placeholder="搜索关键词"
                      style="width: 550px"></el-input>
          </div>
          <div class="search"
               @click="search">
            SEARCH
          </div>
        </div>
        <div class="master-view-third-container-main"
             v-if="articleList.length > 0">
          <div class="master-view-third-container-main-item"
               v-for="(item, index) in articleList"
               :key="index"
               @click="linkTo(item.link, '_blank')">
            <p>
              {{ item.title }}
            </p>
            <p>
              {{ item.modified.split(' ')[0] }} >
            </p>
          </div>
        </div>
      </div>
    </div>
    <Common-activies></Common-activies>
    <Common-footer></Common-footer>
  </div>
</template>

<script>
import CommonFooter from '../components/common-footer';
import CommonActivies from '../components/common-activities';

export default {
  name: 'master-view',
  data () {
    return {
      input: '',
      articleList: []
    }
  },
  mounted () {
  },
  created () {
    this.$api.getArticles({
      category: 'tour',
      num: 8
    }).then(res => {
      this.articleList = res.articles;
    })
  },
  computed: {
  },
  methods: {
    linkTo (url, type) {
      if (type) {
        window.open(url, type);
      } else {
        this.$router.push({
          name: url
        })
      }
    },
    toAboutUsDetails () {
      this.$router.push({
        name: 'aboutUs/details'
      })
    },
    search () {
      if (this.input) {
        this.$api.getArticles({
          category: 'tour',
          q: this.input,
          num: 8
        }).then(res => {
          this.articleList = res.articles;
        })
      } else {
        this.$api.getArticles({
          category: 'tour',
          num: 8
        }).then(res => {
          this.articleList = res.articles;
        })
      }
    }
  },
  components: {
    CommonFooter,
    CommonActivies
  }
}
</script>
