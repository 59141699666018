<style lang="less" scoped >
.activities {
  width: 100%;
  position: relative;
  &-container {
    width: 100%;
    height: 100%;
    max-width: 1104px;
    margin: 0 auto;
    // position: absolute;
    // left: 50%;
    // top: 50%;
    // transform: translate(-50%, -50%);
    color: rgba(222, 230, 255, 1);
    p:nth-child(1) {
      width: 100%;
      text-align: center;
      font-size: 30px;
      font-weight: 400;
      line-height: 42px;
      letter-spacing: 4px;
    }
    p:nth-child(2) {
      width: 100%;
      text-align: center;
      font-size: 10px;
      font-weight: 400;
      line-height: 14px;
      letter-spacing: 4px;
      margin-bottom: 64px;
    }
  }
  &-container-main {
    width: 100%;
    display: flex;
    justify-content: space-between;
    &-item {
      width: 20%;
      position: relative;
      &:hover {
        .img-hover {
          display: block;
          cursor: pointer;
        }
      }
      img {
        width: 100%;
      }
      .img-hover {
        display: none;
        width: 100%;
        height: 37%;
        position: absolute;
        bottom: 0;
        left: 0;
        background: rgba(7, 9, 17, 0.8);
        p {
          font-family: "PingFangSC-Regular", "PingFang SC";
          font-weight: 400;
          color: rgba(222, 230, 255, 1);
          margin: 0;
          padding-left: 20px;
          text-align: left;
          &:nth-child(1) {
            font-size: 20px;
          }
          &:nth-child(2) {
            font-size: 12px;
          }
        }
      }
    }
  }
}
</style>
<template>
  <div class="activities">
    <div class="activities-container">
      <p v-show="$Cookies.get('cecc-lang') !== 'en'">
        相关活动
      </p>
      <p>
        ACTIVITIES
      </p>
      <div class="activities-container-main">
        <div class="activities-container-main-item">
          <img src="../assets/about-1.png"
               alt="">
          <!-- <div class="img-hover"
               @click="linkTo('http://www.cecc.sh.cn/news/html/?396.html', '_blank')"> -->
          <div class="img-hover"
               @click="linkTo('aboutUs')">
            <p>
              SWCES
            </p>
            <p>
              上海世界创意经济峰会
            </p>
          </div>
        </div>
        <div class="activities-container-main-item">
          <img src="../assets/about-2.png"
               alt="">
          <div class="img-hover"
               @click="linkTo('CWF')">
            <p>
              CWF
            </p>
            <p>
              世界创意峰会
            </p>
          </div>
        </div>
        <div class="activities-container-main-item">
          <img src="../assets/about-3.png"
               alt="">
          <div class="img-hover"
               @click="linkTo('IDC')">
            <p>
              IDC 2020
            </p>
            <p>
              全球创意设计大赛
            </p>
          </div>
        </div>
        <div class="activities-container-main-item">
          <img src="../assets/about-4.png"
               alt="">
          <div class="img-hover"
               @click="linkTo('fairviewJiangnan')">
            <p>
              JinXiu
            </p>
            <p>
              锦绣江南名品榜
            </p>
          </div>
        </div>
        <!-- <div class="activities-container-main-item">
          <img src="../assets/about-5.png"
               alt="">
          <div class="img-hover"
               @click="linkTo('http://www.cecc.sh.cn/product/class/?77.html', '_blank')">
            <p>
              嗨客行
            </p>
            <p>
              行走的文化
            </p>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'activities',
  props: {
  },
  data () {
    return {
    }
  },
  created () {
  },
  methods: {
    linkTo (url, type) {
      if (type) {
        window.open(url, type);
      } else {
        this.$router.push({
          name: url
        })
      }
    }
  }
}
</script>
