<style lang="less" scoped >
.news {
  width: 100%;
  position: relative;
  margin: 68px 0;
  &-wrapper {
    width: 100%;
    height: 100%;
    max-width: 1104px;
    margin: 0 auto;
    color: rgba(222, 230, 255, 1);
    &-first {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      &-left {
        font-size: 20px;
        font-weight: 400;
        line-height: 28px;
      }
      &-right {
        width: 68px;
        height: 24px;
        text-align: center;
        cursor: pointer;
        font-size: 10px;
        font-weight: 500;
        line-height: 24px;
        border-radius: 4px;
        border: 1px solid rgba(222, 230, 255, 1);
      }
    }
    &-second {
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      height: 28px;
      &-left {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
      }
      &-right {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }
}
</style>
<template>
  <div class="news">
    <div class="news-wrapper"
         v-show="$Cookies.get('cecc-lang') !== 'en'">
      <div class="news-wrapper-first">
        <div class="news-wrapper-first-left">
          新闻动态
        </div>
        <div class="news-wrapper-first-right"
             @click="linkTo('moreNews')">
          MORE
        </div>
      </div>
      <div class="news-wrapper-second"
           v-for="(item, index) in newsList"
           :key="index"
           @click="linkToNews(item)">
        <div class="news-wrapper-second-left">
          {{item.title}}
        </div>
        <div class="news-wrapper-second-right">
          {{ item.created.split(' ')[0] }} &nbsp; &nbsp; >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'news',
  props: {
  },
  data () {
    return {
      newsList: []
    }
  },
  created () {
    this.$api.getArticles({
      category: 'news',
      num: 5
    }).then(res => {
      this.newsList = res.articles;
    })
  },
  methods: {
    linkTo (url, type) {
      if (type) {
        window.open(url, type);
      } else {
        this.$router.push({
          name: url
        })
      }
    },
    linkToNews (item) {
      if (item.source === 0) {
        this.$router.push({
          name: 'news',
          params: item
        })
      } else {
        this.linkTo(item.link, '_blank');
      }
    }
  }
}
</script>
